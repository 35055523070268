var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { width: "100%", height: "100vh" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onBlur.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.back.apply(null, arguments)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "reason-item-hull",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "reason-item",
            {
              ref: "reasonItem",
              attrs: { reasonText: _vm.reasonText },
              on: { change: _vm.onChange, selectData: _vm.selectDataFn },
            },
            [
              _c(
                "div",
                { staticClass: "use-ocr", on: { click: _vm.openCaseCamera } },
                [_c("i", { staticClass: "iconfont icon-camera" })]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "change-case-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onBlur.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "van-button",
            {
              attrs: { plain: "", color: "#fff", type: "primary" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.vanBtnClearFn.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticStyle: { color: "#999999" } }, [
                _vm._v("清除"),
              ]),
            ]
          ),
          _c(
            "van-button",
            {
              attrs: { type: "primary", color: "#009FFC" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.vanBtnRetrieveFn.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticStyle: { color: "#FFF" } }, [
                _vm._v("重新检索"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }