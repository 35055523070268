<template>
  <div @click.stop="onBlur" style="width:100%;height:100vh">
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click.stop="back"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <div class="reason-item-hull" @click.stop="">
      <reason-item
        ref="reasonItem"
        @change="onChange"
        :reasonText="reasonText"
        @selectData="selectDataFn"
      >
        <div class="use-ocr" @click="openCaseCamera">
          <i class="iconfont icon-camera"></i>
        </div>
      </reason-item>
    </div>
    <div class="change-case-button" @click.stop="onBlur">
      <van-button @click.stop="vanBtnClearFn" plain color="#fff" type="primary"
        ><span style="color:#999999">清除</span></van-button
      >
      <van-button @click.stop="vanBtnRetrieveFn" type="primary" color="#009FFC"
        ><span style="color:#FFF">重新检索</span></van-button
      >
    </div>
  </div>
</template>
<script>
import ReasonItem from '@components/reasonPicker'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'changeCase',
  components: {
    ReasonItem,
  },
  data() {
    return {
      // openCaseCamera: '', // 解决报错
      title: '添加案由',
      reasonSuggestion: '',
      reasonText: '',
      selectData: null,
    }
  },
  created() {
    console.log('添加案由')
  },
  computed: {
    ...mapState('ana', ['changeCaseData']),
  },
  methods: {
    onBlur() {
      this.reasonText = this.oldName
      if (this.selectData) {
        this.vanBtnClearFn()
      }
      console.log('onBlur - val:', this.reasonText)
    },
    onChange(val) {
      this.oldName = val
      this.reasonText = ''
      console.log('onChange - val:', this.selectData, val)
    },
    openCaseCamera() {
      console.log('opencase camera')
    },
    back() {
      if (
        this.directGoNative ||
        window.nativeEnterIndex >= this.$router.length
      ) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    vanBtnClearFn() {
      console.log('清除')
      this.$refs.reasonItem.clearReason()
      this.selectData = null
    },
    ...mapMutations('ana', ['SET_CHANGECASEDATA']),
    vanBtnRetrieveFn() {
      console.log('重新检索', this.selectData, this.reasonText)
      if (!this.selectData || !this.selectData.text) {
        return this.$notify('请在案由列表中选择案由')
      }
      // 重新检索之后 传  name + id   传给ana之 ana拼接数据 展示
      // bus 修改index
      // go(-1) 返回上个页面
      // console.log(this.semanticData)
      const arr = this.changeCaseData
      arr.push(this.selectData)
      this.SET_CHANGECASEDATA(arr)
      this.$router.go(-1)
    },
    selectDataFn(obj) {
      this.selectData = obj
    },
  },
}
</script>

<style scope>
.reason-item-hull {
  padding: 45px 0;
}
.change-case-button {
  display: flex;
  margin-top: 45px;
}
.change-case-button > button:nth-child(1) {
  flex-grow: 4;
}
.change-case-button > button:nth-child(2) {
  flex-grow: 6;
}
</style>
